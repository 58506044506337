import { cx } from '@emotion/css'
import {
	ChangeCountryLanguageStyled,
	MiddleBottomWrapper,
	Copyright,
	StorelocatorLinkStyled,
} from './middle-bottom-area.styled'
import { useIntl } from 'react-intl'
import { useTheme } from '@mui/system'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import Typography from '@bluheadless/ui/src/atoms/typography'
import RichContent from '@bluheadless/ui/src/particles/rich-content'
import MapPinIcon from '@bluheadless/ui/src/atoms/icons/map-pin-icon'

const MiddleBottomArea = ({ copyrightProps, className }) => {
	const { copyright } = useConfig()
	const { formatMessage } = useIntl()
	const theme = useTheme()

	return (
		<MiddleBottomWrapper className={cx('MiddleBottomArea-root', className)}>
			<StorelocatorLinkStyled
				className="storelocator-link"
				buttonProps={{
					StartIcon: MapPinIcon,
					variant: 'textLink',
					color: 'primary',
					underline: 'none',
					iconProps: { fontSize: 'medium' },
					label: formatMessage({ id: 'footer_store_locator_link_label' }),
				}}
			/>
			<ChangeCountryLanguageStyled
				className="change-country-language-trigger-footer"
				customLabel={formatMessage({ id: 'change_country_label' })}
				showFlag={true}
				showCurrencySymbol={false}
				showArrowDown={false}
				showCountryCode={false}
				showCountryName
			/>
			<Copyright>
				<Typography
					variant={'subtitle1'}
					sx={{
						color: theme.palette.footer.color,
					}}
					component="div"
					{...copyrightProps}
				>
					<RichContent content={copyright} />
				</Typography>
			</Copyright>
		</MiddleBottomWrapper>
	)
}

export default MiddleBottomArea
