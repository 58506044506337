import { styled } from '@mui/system'
import { ChangeCountryLanguage } from '../../change-country-language'
import StorelocatorLink from '@bluheadless/ui/src/organisms/footer/storelocator-link'

const MiddleBottomWrapper = styled('div')`
	flex-wrap: wrap;
	padding: 0;
	background-color: ${({ theme }) => theme.palette.footer.bgMiddleBottom};
	color: ${({ theme }) => theme.palette.footer.color};
	display: flex;
	justify-content: flex-start;
	padding: 0 0 20px;
	flex-wrap: wrap;
	column-gap: 0;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	.MuiSvgIcon-root {
		margin-right: var(--size-8);
	}
	&.change-country-language-trigger-footer {
		line-height: 1;
		flex-grow: 1;
		justify-content: flex-start;
		padding: var(--size-20) 0 var(--size-20) var(--size-20);
		border-bottom: 1px solid var(--color-grey100);

		font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		> span {
			font-size: ${({ theme }) => theme.typography.pxToRem(18)};
		}
		&::after {
			display: inline-block;
			content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmb250LXNpemU9IjEycHgiIGNsYXNzPSJNdWlTdmdJY29uLXJvb3QgTXVpU3ZnSWNvbi1mb250U2l6ZVNtYWxsIGNzcy0xYXFkNDF1LU11aVN2Z0ljb24tcm9vdCIgZm9jdXNhYmxlPSJmYWxzZSIgYXJpYS1oaWRkZW49InRydWUiPjxwYXRoIGQ9Ik03LjkyIDE5LjIzMkwxNi4wOCAxMiA3LjkyIDQuNzY4IiBzdHJva2U9ImN1cnJlbnRDb2xvciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEuNTM2Ij48L3BhdGg+PC9zdmc+);
			height: 12px;
			width: 12px;
			margin-left: 0;
		}
		${({ theme }) => theme.breakpoints.up('md')} {
			border-top: 1px solid var(--color-grey100);

			font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			flex-grow: 0;
			width: auto;
			border-bottom: none;
			border-right: 1px solid var(--color-grey100);
			padding: 19px 16px 19px var(--size-20);
			justify-content: center;
			&::after {
				display: none;
			}
			> span {
				font-size: ${({ theme }) => theme.typography.pxToRem(12)};
			}
		}
	}
`

const Copyright = styled('div')`
	margin-right: 0;
	margin-left: auto;
	padding: 0 var(--size-20);
	text-align: left;
	width: 100%;

	.MuiTypography-root {
		line-height: var(--size-20);
		color: var(--color-grey50);
		a {
			color: var(--color-grey50);
			text-decoration-color: var(--color-grey50);
		}
	}
	> .MuiTypography-root {
		padding: 19px 0;
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		text-align: right;
		flex: 1;
		padding: 0;
		padding-right: var(--size-16);
		border-top: 1px solid var(--color-grey100);
		.MuiTypography-root {
			line-height: var(--size-18);
		}
	}
`

const StorelocatorLinkStyled = styled(StorelocatorLink)`
	color: var(--color-primary);
	font-size: ${({ theme }) => theme.typography.pxToRem(18)};
	letter-spacing: unset;
	padding: var(--size-20);
	padding-left: 18px;
	line-height: 1;
	border-bottom: 1px solid var(--color-grey100);
	width: 100%;

	.MuiButton-startIcon {
		padding-top: 4px;
		font-size: ${({ theme }) => theme.typography.pxToRem(16)};
	}
	&:hover,
	&:focus {
		text-decoration: none;
		border-right: 1px solid var(--color-grey100);
		border-bottom: 1px solid var(--color-grey100);
	}
	${({ theme }) => theme.breakpoints.up('md')} {
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
		border-top: 1px solid var(--color-grey100);
		border-right: 1px solid var(--color-grey100);
		padding: 0;
		padding-right: var(--size-20);
		padding-left: 13px;
		border-bottom: 0;
		width: auto;
		&:hover,
		&:focus {
			border-top: 1px solid var(--color-grey100);

			text-decoration: none;
			border-right: 1px solid var(--color-grey100);
			border-bottom: 0;
		}
		.MuiButton-startIcon {
			font-size: ${({ theme }) => theme.typography.pxToRem(24)};
		}
	}
`

export { ChangeCountryLanguageStyled, MiddleBottomWrapper, Copyright, StorelocatorLinkStyled }
